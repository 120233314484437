export default [
  {
    path: "/sepa",
    name: "sepa.index",
    meta: {
      auth: true,
      module: "module.PROF.SEPA",
      submodule: "",
      hasAccess: true, // no permission check (a settings page is unknown at backend-side)
    },
    component: () =>
      import(/* webpackChunkName: "sepa" */ "@/views/sepa/Management.vue"),
  },
];
