var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumb-wrapper" },
    [
      _c(
        "b-breadcrumb",
        [
          _c(
            "b-breadcrumb-item",
            {
              staticClass: "link--icon",
              attrs: { to: { name: "module.HOME" } },
            },
            [
              _c("b-icon", {
                attrs: {
                  icon: "house",
                  scale: "1.25",
                  "shift-v": "1.25",
                  "aria-hidden": "true",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Home")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.bcrumbs
            ? _vm._l(_vm.bcrumbs, function (element, index) {
                return _c("b-breadcrumb-item", {
                  key: index,
                  class: `${!element.path ? "no-link" : ""}`,
                  attrs: {
                    text: element.text,
                    disabled:
                      _vm.isLastElement(index) || _vm.hasDisabled(element),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.shortcut(element.path, null, element.tab)
                    },
                  },
                })
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }