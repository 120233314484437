var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        position: "sticky",
        top: "0",
        "z-index": "3",
        "border-bottom": "1px solid #cdc5c0",
      },
    },
    [
      _vm.isLogged && !_vm.$store.state.sidebar.locked
        ? _c("navbar", {
            attrs: { pTitle: _vm.pageTitle },
            on: { signoff: _vm.logout },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLogged && !_vm.$store.state.sidebar.locked
        ? _c(
            "div",
            {
              staticClass: "wrapper",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "background-color": "#e1ddda",
                padding: "0.3rem 2rem",
              },
            },
            [
              _c("breadcrumbs", {
                attrs: { bcrumbs: _vm.clearedBreadcrumbList },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "version text-sm-right",
                  staticStyle: { "white-space": "nowrap", "font-size": "10px" },
                },
                [_vm._v("\n      Versione: 3." + _vm._s(_vm.cliVer) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }