var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("accordion-sidebar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.$store.state.sidebar.locked,
            expression: "!$store.state.sidebar.locked",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dashboard" },
        [
          _vm.isLogged ? _c("custom-header") : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "component-fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }