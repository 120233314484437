import { mapActions } from "vuex";
export default {
  data() {
    return {
      allTaskables: {
        brokers: {
          0: {
            routeName: "brokers.index0",
            filterName: "filterSalesmen",
            hash: null,
            label: "Produttori",
          },
          1: {
            routeName: "brokers.index1",
            filterName: "filterCooperator",
            hash: null,
            label: "Collaboratori",
          },
        },
        claims: {
          routeName: "module.PFOLIO",
          filterName: "filterClaim",
          hash: "#Claims",
          label: "Sinistri",
        },
        agency_contacts: {
          routeName: "module.PFOLIO",
          filterName: "filterContact",
          hash: "#AgencyContacts",
          label: "Contatti di Agenzia",
        },
        insurance_policies: {
          routeName: "module.PFOLIO",
          filterName: "filterPolicy",
          hash: "#Policies",
          label: "Polizze",
        },
        insurance_ancillaries: {
          routeName: "module.TIT.TINS",
          filterName: "filterTitle",
          hash: "#Titles",
          label: "Titoli",
        },
        book_entries: {
          routeName: "module.CONT.FCAS",
          filterName: "filterBookEntry",
          hash: "#BookEntries",
          label: "Registrazione a Foglio Cassa",
        },
        registries: {
          routeName: "module.PFOLIO",
          filterName: "filterRegistry",
          hash: "#Registries",
          label: "Anagrafiche",
        },
        registry_groups: {
          routeName: "module.PFOLIO",
          filterName: "filterRegistryGroup",
          hash: "#RegistryGroups",
          label: "Gruppi",
        },
      },
      allTaskTabs: {
        0: "#Appointments",
        1: "#Todo",
        2: "#Notes",
      },
    };
  },
  methods: {
    shortcut(
      routeName,
      id,
      hash,
      filterName,
      criteria,
      query,
      breadcrumbs,
      data,
      fromTab
    ) {
      let args = {};
      args.params = {};

      if (routeName) {
        args.name = routeName;
      }
      if (id) {
        args.params.id = id;
      }
      if (hash) {
        args.hash = hash;
      }
      if (query) {
        args.query = query;
      }
      if (data) {
        args.params.data = data;
      }
      if (fromTab) {
        args.params.fromTab = fromTab;
      }

      if (breadcrumbs) {
        console.debug("ShorcutMixin breadcrumbs: ", breadcrumbs);
        // store breadcrumbs
        this.saveBCS({ breadcrumbs });
      }
      console.debug("ShorcutMixin args: ", args);
      // if (filterName && criteria) {
      //   console.debug("ShorcutMixin criteria: ", criteria);
      //   let name = filterName;
      //   // this.removePaginationByName(name); // non ha senso atterrare su pagina 2, ma qui non serve comunque
      //   this.saveFilterByName({ name, criteria }).then(() => {
      //     // change route after criteria is saved!!!
      //     this.$router.push(args);
      //   });
      // } else {
      //   this.$router.push(args);
      // }
      // il fatto è criteria {id: X} e non serve a nulla salvarlo!
      // anzi, non va salvato perché procura l'autofetch
      this.$router.push(args);
    },
    generateTaskableShortcut(task) {
      // WARNING: do not use as general purpose util, WON'T WORK
      // here task is a raw "tasks" table record
      // no relations included (e.g.: "brokers" or whatever conf in allTaskables)
      // task["brokers"] NOT AVAILABLE
      // QUI il task è proprio la "nota"
      let conf;
      let taskable_id;
      // let taskable;
      let routeName;
      let hash;
      let filterName;
      let queryHash;
      let link;
      let t = this.getTaskableResource(task.pivot.taskable_type);
      // if (t === "brokers") {
      //   let status_broker = task[t].status_broker.value;
      //   conf = this.allTaskables[t][status_broker];
      // } else {
      //   conf = this.allTaskables[t];
      // }
      conf = this.allTaskables[t];
      taskable_id = task.pivot.taskable_id;
      routeName = conf.routeName;
      hash = conf.hash;
      filterName = conf.filterName;
      queryHash = this.allTaskTabs[task.task_type.value];
      // rest.extendedProps.taskable_id = taskable_id;
      // rest.extendedProps.taskable = taskable;
      link = () => {
        // this.$router.push({ name: "registries.edit", params: { id: `${id}` } });
        this.shortcut(
          routeName,
          null,
          hash,
          filterName,
          { id: taskable_id },
          {
            click: "openModal",
            id: taskable_id,
            modalName: "modal",
            hash: queryHash,
          },
          null
          // task
        );
      };
      return { link };
    },
    getTaskableResource(taskable) {
      // "App\Models\Registry"
      let ret = "";
      let s = taskable.split("\\");
      switch (s[s.length - 1]) {
        case "Registry":
          ret = "registries";
          break;
        case "InsurancePolicy":
          ret = "insurance_policies";
          break;
        case "AgencyContacts":
          ret = "agency_contacts";
          break;
        default:
          break;
      }
      return ret;
    },
    isLastElement(index) {
      return index === this.bcrumbs ? this.bcrumbs.length - 1 : null;
    },
    hasDisabled(element) {
      return element.disabled;
    },
    ...mapActions("filters", { saveFilterByName: "saveByName" }),
    ...mapActions("breadcrumbs", { saveBCS: "save" }),
  },
};
