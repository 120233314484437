var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLogged
    ? _c("sidebar-menu", {
        staticClass: "sidebar",
        attrs: {
          menu: _vm.final_menu,
          collapsed: _vm.isNavClose,
          theme: "white-theme",
          disableHover: true,
          hideToggle: true,
          showOneChild: true,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [_c("div", { style: _vm.cssProps })]
              },
              proxy: true,
            },
            {
              key: "dropdown-icon",
              fn: function () {
                return [
                  _c(
                    "span",
                    [
                      _c("b-icon", {
                        attrs: { icon: "chevron-compact-right" },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1636716466
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }