import Repository from "@/repositories/Repository";

export default {
  // a collection of mixed api calls, only index!!!
  agency_brokers(perPage = 0) {
    let resource = `/brokers?byAttribute[status_broker]=2&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  attributes(perPage = 0) {
    let resource = `/attributes?byAttribute[is_customizable]=Y&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  book_tags(perPage = 0) {
    let resource = `/book_tags?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  branches(perPage = 0) {
    let resource = `/risk_branches?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  cash_references(perPage = 0) {
    let resource = `/causali-prima-nota?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  consolidated_at(queryString) {
    // ?byCalendar=1970-01-01,(data di oggi - 1)&sort=consolidated_at&dir=desc
    let resource = `/consolidated_at?${queryString}`;
    return Repository.get(`${resource}`);
  },
  conti_prima_nota() {
    return Repository.get("/conti-prima-nota");
  },
  cooperators(perPage = 0) {
    let resource = `/brokers?byAttribute[status_broker]=1&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  councils(province_id = null, council_partial_name = null, perPage = 0) {
    let resource = `/v2/councils?perPage=${perPage}${
      province_id ? `&byProvince=${province_id}` : ""
    }${council_partial_name ? `&byTitle=${council_partial_name}` : ""}`;
    return Repository.get(`${resource}`);
  },
  counters(resource, queryString) {
    return Repository.get(`/${resource}?${queryString}`);
  },
  countries(perPage = 0) {
    let resource = `/countries?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  // FUTURE
  // dictionaries(perPage = 0) {
  //   let resource = `/dictionaries?perPage=${perPage}`;
  //   return Repository.get(`${resource}`);
  // },
  insurers(perPage = 0) {
    let resource = `/insurers?byImporter&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  lisa_for_apps(user_id, perPage = 0) {
    let resource = `/lisa_for_apps?bySpecifiedUser=${user_id}&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  bank_accounts(perPage = 0) {
    let resource = `/bank_accounts?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  mandate_codes(perPage = 0) {
    let resource = `/mandate_codes?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  payment_references(perPage = 0) {
    let resource = `/payment_references?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  products(perPage = 0) {
    let resource = `/insurance_risks?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  provinces(country_id, perPage = 0) {
    let resource = `/provinces?perPage=${perPage}${
      country_id ? `&byCountry[id]=${country_id}` : ""
    }`;
    return Repository.get(`${resource}`);
  },
  relative_types(perPage = 0) {
    let resource = `/relative_types?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  reports(perPage = 0) {
    let resource = `/reports?byUser&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  risks(perPage = 0) {
    let resource = `/risk_types?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  salesmen(perPage = 0) {
    let resource = `/brokers?byAttribute[status_broker]=0&perPage=${perPage}&sort[byAttribute]=BUID&dir[byAttribute]=asc`;
    return Repository.get(`${resource}`);
  },
  sectors(perPage = 0) {
    let resource = `/sectors?byTreasury&perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  treasuries(perPage = 0) {
    let resource = `/treasuries?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
  tags(term, perPage = 0) {
    let resource = `/tags?perPage=${perPage}&byAttribute[term]=${term}&sort[byAttribute]=affinity&dir[byAttribute]=desc`;
    return Repository.get(`${resource}`);
  },
  // Utenti
  users(perPage = 0) {
    let resource = `/users?perPage=${perPage}`;
    return Repository.get(`${resource}`);
  },
};
