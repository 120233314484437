var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "searchResults",
            size: "lg",
            "ok-only": "",
            "ok-title": "Chiudi",
            "ok-variant": "lisaweb",
            "header-bg-variant": "lisaweb",
          },
          on: {
            ok: function ($event) {
              return _vm.$emit("close")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      attrs: { slot: "modal-title" },
                      slot: "modal-title",
                    },
                    [
                      _c("b-icon", { attrs: { icon: "search" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Risultati Ricerca Rapida")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        title: "Chiudi",
                        "data-dismiss": "modal",
                      },
                      on: {
                        click: function ($event) {
                          _vm.$emit("close")
                          cancel()
                        },
                      },
                    },
                    [_vm._v("\n        ×\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("b-row", [
            Object.values(_vm.results).some((e) => e.length)
              ? _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("p", [
                      _vm._v("\n          La ricerca di "),
                      _c("b", [_vm._v(_vm._s(_vm.term))]),
                      _vm._v(" ha prodotto i seguenti risultati\n        "),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.results, function (array, entity, index1) {
                      return _c(
                        "div",
                        { key: "l1-" + index1, staticClass: "mt-3" },
                        [
                          array.length
                            ? _c(
                                "b-card",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    header: _vm.getEntityLabel(entity),
                                    "header-tag": "header",
                                  },
                                },
                                [
                                  _c("b-card-text", [
                                    _c(
                                      "ul",
                                      _vm._l(array, function (obj, index2) {
                                        return _c(
                                          "span",
                                          { key: "l2-" + index2 },
                                          _vm._l(
                                            obj.found,
                                            function (result, index3) {
                                              return _c(
                                                "li",
                                                { key: "l3-" + index3 },
                                                [
                                                  _c(
                                                    "b-link",
                                                    {
                                                      attrs: { href: entity },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          _vm.openEntityModal(
                                                            result.id,
                                                            entity,
                                                            _vm.getFoundLabel(
                                                              result,
                                                              entity
                                                            )
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getFoundLabel(
                                                              result,
                                                              entity
                                                            )
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                      tag: " +
                                                        _vm._s(obj.term) +
                                                        "\n                      "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      }),
                                      0
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _c("div", { staticClass: "col-md-12" }, [
                  _vm._v("Nessun risultato."),
                ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.searchmodalName,
            size: "xl",
            "ok-only": "",
            "ok-title": "Chiudi",
            "ok-variant": "lisaweb",
            "header-bg-variant": "lisaweb",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.onModalHidden },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      attrs: { slot: "modal-title" },
                      slot: "modal-title",
                    },
                    [
                      _c("b-icon", { attrs: { icon: "search" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.detailTitle) + " ")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        title: "Chiudi",
                        "data-dismiss": "modal",
                      },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("\n        ×\n      ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-tabs",
                {
                  attrs: { "content-class": "pt-1", lazy: "" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                _vm._l(_vm.tabs[_vm.entity], function (tab, index) {
                  return _c(
                    "b-tab",
                    {
                      key: index,
                      attrs: {
                        active: index === _vm.tabIndex,
                        title: tab.text,
                        "title-item-class": "lisaweb",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setTab(tab, index, _vm.entity)
                        },
                      },
                    },
                    [
                      _c(
                        "keep-alive",
                        [
                          _vm.selected === tab.name
                            ? _c(_vm.selected, {
                                tag: "component",
                                attrs: { resourceId: _vm.modalResourceId },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }